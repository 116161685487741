import { TABLEAU_DASHBOARDS } from 'data/tableauDashboards';

export const getDefaultRedirectPage = user => {
  // set default redirect page to the first element within user views
  // if this happens to be price, redirect to /get-rent-estimate
  // otherwise, if it is not dashboard, redirect to /dashboard/{first_element_in_user_views}
  // default: redirect to /dashboard
  if (!user) {
    return '/login';
  }
  if (user && user.userType !== 'landlord') {
    return '/properties';
  }
  if (user && user.userType === 'landlord') {
    const getPageIdByViewName = viewName => {
      for (const dashboard of TABLEAU_DASHBOARDS) {
        const foundTab = dashboard.dashboardTabs.find(tab => tab.viewName === viewName);
        if (foundTab) {
          return dashboard.pageId;
        }
      }
      return null; // Return null if the viewName is not found
    };
    // if (!user.views) return '/dashboard/properties';
    if (user.views.includes('bookings')) return '/dashboard/bookings';
    if (user.views.includes('dashboard')) {
      if (
        (user.dashboardViews.length === 0 && user.views.includes('price+')) ||
        (user.dashboardViews.length === 1 && user.dashboardViews[0] === 'revenue_management')
      )
        return '/dashboard/analytics?page=reporting';
      else return `/dashboard/analytics?page=${getPageIdByViewName(user.dashboardViews[0])}`;
    } else {
      return '/dashboard/properties';
    }
  }
  return '/dashboard/properties';
};
